import { Controller } from "@suttyweb/stimulus";

/*
 * Slider con scroll automático
 */
export default class extends Controller {
  static targets = ["control"];

  active(control) {
    if (!control) return;

    this.controlTargets.forEach((other) =>
      other.classList.toggle("active", control.href === other.href)
    );
    this.current = this.controlTargets.indexOf(control);

    const slide = this.element.querySelector(
      decodeURI(new URL(control.href).hash)
    );

    if (!slide) return;

    const scrollY = window.pageYOffset;

    slide.scrollIntoView();

    window.scrollTo(0, scrollY);
  }

  activate(event) {
    event.preventDefault();

    // XXX: En Firefox, el target del evento también puede ser el
    // contenido del link :/
    let t = event.target;

    while (!t.href) t = t.parentElement;

    this.active(t);
  }

  get current() {
    return parseInt(this.data.get("current")) || 0;
  }

  set current(value) {
    this.data.set("current", value);
  }

  get next() {
    const next = this.current + 1;

    return this.controlTargets[next] ? next : 0;
  }

  get inViewport() {
    const bounding = this.element.getBoundingClientRect();

    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
