import { Controller } from "@suttyweb/stimulus";

export default class extends Controller {
  static targets = ["item"];

  connect() {
    this.scroll_section_event = this._scroll_section_event.bind(this);

    window.addEventListener("scroll:section", this.scroll_section_event);
  }

  disconnect() {
    window.removeEventListener("scroll:section", this.scroll_section_event);
  }

  _scroll_section_event(event) {
    this.update(event.detail.id);
  }

  get items() {
    if (!this._items) {
      this._items = {};

      for (const item of this.itemTargets) {
        this._items[item.href.split("#")[1]] = item;
      }
    }

    return this._items;
  }

  update(id) {
    for (const item of Object.values(this.items)) {
      item.classList.remove("active");
    }

    if (this.items[id]) this.items[id].classList.add("active");
  }
}
